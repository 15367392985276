<template>
  <AtroButton
    text="Logout"
    type="light"
    @click="
      signOut({
        callbackUrl: '/login',
      })
    "
  />
</template>

<script setup lang="ts">
import type { ButtonProps } from '@atro/components'
const { signOut } = useAuth()

export interface Props extends ButtonProps {}

defineProps<Props>()
</script>
